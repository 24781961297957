import kunleBadmus from '../assets/img/team/kunleBadmus.jpg';
import GeneTeveler from '../assets/img/team/GeneTeveler.jpg';
import rubel from '../assets/img/team/Rubel.jpg';
import HasithaJayawardana from '../assets/img/team/HasithaJayawardena.jpg';
import abu from '../assets/img/team/abu.jpg';
// import sayed from '../assets/img/sayed.png';
import dipu from '../assets/img/team/dipu.jpg';
import shashika from '../assets/img/team/shashika.jpg';
import sayed from '../assets/img/team/sayed.jpg';




export const executive = [
    {
        id: 1,
        src: kunleBadmus,
        name: 'kunle Badmus',
        position: 'CEO',
    },
    {
        id: 2,
        src: GeneTeveler,
        name: 'Gene Teveler',
        position: 'COO'
    },


];

export const team = [
    {
        id: 1,
        src: rubel,
        name: 'Rubel Ahmed',
        position: 'Manager / Sr. Software Engineer',
    },
    {
        id: 2,
        src: HasithaJayawardana,
        name: 'Hasitha Jayawardana',
        position: 'Sr. Software Engineer'
    },
    {
        id: 6,
        src: shashika,
        name: 'Shashika Shiromini',
        position: 'QA Engineer'
    },


];


